import { SubmitUserDetailsSuccess } from 'ouisys-engine/dist/reducers/creditCardFlow/CreditCardTypes';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import * as React from 'react';
import { injectIntl, Translate } from '../../localization/index';

const CreditCardStep = ({ gateway_url }: SubmitUserDetailsSuccess) => {
   return (
    <div>
      {/* <div className="wpwl-container">
        <form className="paymentWidgets" data-brands="VISA MASTER"></form>
      </div> */}
    </div>
  );
};

export default injectIntl(CreditCardStep);
