// dependencies
import { Disclaimers, PricePoint, TopLegals } from 'ouisys-component-library';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import moRedirFlowMatch from 'ouisys-engine/dist/flows/moRedirFlow';
import { MoRedirFlowActionMaps, submitMSISDNAction } from 'ouisys-engine/dist/reducers/moRedirFlow';
import { MSISDNEntrySuccess } from 'ouisys-engine/dist/reducers/moRedirFlow/MoRedirTypes';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import { handleAutoSubmitMsisdn } from 'ouisys-engine/dist/utilities/handleSubmitNumber';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local
import config from '../../../config.json';
import MORedir from '../../components/MORedir';
import PhoneNumberEntryStep from '../../components/PhoneNumberEntryStep';
import { legals, TranslationProvider } from '../../localization/index';

const MoRedirFlow = (props: {
  nextData: IdentifyFlowOptionsResult;
  locale: string;
  msisdn: string;
  operator: string;
  setMsisdn: (msisdn: string) => void;
}) => {
  const dispatch = useDispatch();
  const { nextAction } = props.nextData;
  const { currentState } = useSelector((state: IApplicationState) => state.moRedirFlow);
  const [msisdnValue, setMsisdnValue] = useState<string>(props.nextData.msisdn || props.msisdn);
  const isHeaderEnrichmentSuccess = props.nextData.isHeaderEnrichmentSuccess
    ? props.nextData.isHeaderEnrichmentSuccess
    : false;

  useEffect(() => {
    handleAutoSubmitMsisdn({
      msisdnValue,
      dispatch,
      submitMSISDNAction,
      isHeaderEnrichmentSuccess,
      setMsisdnValue
    });
  }, []);

  return (
    <TranslationProvider locale={props.locale}>
      <div>

        {/* top legals */}
        <div className="topLegalsWrap">
          <TopLegals className="topLegals" locale={props.locale} legals={legals} />
        </div>

        <div id="divFocus">
          {
            moRedirFlowMatch({
              msisdnEntry: (rds) => (
                <div>
                  {RDS.WhenSuccess<MSISDNEntrySuccess, JSX.Element>(
                    <PhoneNumberEntryStep
                      isHeaderEnrichmentSuccess={isHeaderEnrichmentSuccess}
                      msisdn={msisdnValue}
                      operator={props.operator}
                      rds={rds}
                      locale={props.locale}
                      onEnd={(_msisdn, bupperNumber, extraParams) => {
                        props.setMsisdn(_msisdn);
                        dispatch(submitMSISDNAction(_msisdn));
                      }}
                    />,
                    (data) => <MORedir {...data} />
                  )(rds)}
                </div>
              )
            })
            (currentState)
            // (mockedMSISDNEntrySuccess.currentState)
            // (mockedMSISDNEntryFailure.currentState)
          }
        </div>

        {/* price point */}
        <PricePoint
          className="price-point"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

        {/* disclaimers */}
        <Disclaimers
          className="disclaimer"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

      </div>
    </TranslationProvider>
  );
};

export default MoRedirFlow;
