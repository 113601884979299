// dependencies
import React, { useEffect } from 'react';
import { PINEntrySuccess } from 'ouisys-engine/dist/reducers/pinFlow/PinTypes';
import { Link } from 'ouisys-component-library';

// local
import { injectIntl, Translate } from '../../localization/index';
import ChainRedirect from '../ChainRedirect';

const ThankYouStep = ({ finalUrl, isAlreadySubscribed }: PINEntrySuccess) => {
  const chainRedirectUrl = typeof window != 'undefined' ? window.pac_analytics.visitor.chainRedirectUrl : null;

  // uncomment this if you need auto redirection
  /*
  useEffect(() => {
    setTimeout(() => {
      if(typeof window !== 'undefined'){
        tracker.advancedInFlow(
          'tallyman.v1-pin',
          'portal-redirect',
          { url: finalUrl }
        )
        window.location.href = finalUrl;
      }
    }, 3000);
  },[]);
  */

  return (
    <div>
      <h3>
        <Translate id="congratsTitle" defaultMessage="Congratulations!" />
      </h3>
      <p>
        {
          isAlreadySubscribed
            ? <Translate id="AlreadySubscribed" defaultMessage="You are already a subscriber!" />
            : <Translate id="congratsText" defaultMessage="You have successfully enrolled in the service." />
        }
      </p>
      <Link href={finalUrl}>
        <Translate id="portalButton" defaultMessage="Go to portal page" />
      </Link>
      {chainRedirectUrl && <ChainRedirect chainRedirectUrl={chainRedirectUrl} />}
    </div>
  );
};

export default injectIntl(ThankYouStep);
