// dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import { IKeywordShortcode, MOLink, Link } from 'ouisys-component-library';
import { backToStart } from 'ouisys-engine/dist/reducers/moFlow';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';

// local
import { injectIntl, Translate } from '../../localization/index';

const MO = ({ keyword, shortcode }: IKeywordShortcode) => {
  const dispatch = useDispatch();

  return (
    <div>

      <MOLink
        className="subscriptionArea moButton"
        onClick={() => {
          tracker.advancedInFlow('click2sms', 'click', { keyword, shortcode });
        }}
        keywordAndShortcode={{ keyword, shortcode }}
      >
        <div className="Label">
          <Translate id="moTitle" defaultMessage="MO_TITLE" />
        </div>
        <div className="btn">
          <Translate id="moButton" defaultMessage="Send SMS" />
        </div>
        <div className="moInstructions">
          <p>
            <Translate id="moLabelSend" defaultMessage="Send" />
            {' '}
            <b>{keyword}</b>
            {' '}
            <Translate id="moLabelTo" defaultMessage="to" />
            {' '}
            <b>{shortcode}</b>
          </p>
        </div>
      </MOLink>

      <Link
        className="tryAgainLink"
        onClick={() => {
          dispatch(backToStart());
          tracker.recedeInFlow(
            'tallyman.v1-pin',
            'MSISDN_ENTRY',
            'requested to change phone number'
          );
        }}
      >
        <Translate id="tryAgainLabel" defaultMessage="Try again" />
      </Link>

    </div>
  );
};

export default injectIntl(MO);
