// dependencies
import { defineMessages, Disclaimers, PricePoint, TopLegals } from 'ouisys-component-library';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import ussdFlowMatch from 'ouisys-engine/dist/flows/ussdFlow';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { submitMSISDNAction, UssdFlowActionMaps } from 'ouisys-engine/dist/reducers/ussdFlow';
import { MSISDNEntrySuccess } from 'ouisys-engine/dist/reducers/ussdFlow/UssdTypes';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import { handleAutoSubmitMsisdn } from 'ouisys-engine/dist/utilities/handleSubmitNumber';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local
import PhoneNumberEntryStep from '../../components/PhoneNumberEntryStep';
import USSD from '../../components/USSD';
import { legals, TranslationProvider } from '../../localization/index';
import config from '../../../config.json';

// error messages messages which might come from backend
const defaultMessages = defineMessages({
  InvalidMSISDN: {
    id: 'InvalidMSISDN',
    defaultMessage: 'This mobile number is incorrect, try again'
  },
  AlreadySubscribed: {
    id: 'AlreadySubscribed',
    defaultMessage: 'You are already a subscriber!'
  },
  UnknownError: {
    id: 'UnknownError',
    defaultMessage: 'An unknown error occurred.'
  },
  TooEarly: {
    id: 'TooEarly',
    defaultMessage: 'You must first submit a mobile number'
  }
});

const UssdFlow = (props: {
  nextData: IdentifyFlowOptionsResult<'submitMSISDNAction'>;
  locale: string;
  msisdn: string;
  operator: string;
  setMsisdn: (msisdn: string) => void;
}) => {
  const dispatch = useDispatch();
  const { nextAction } = props.nextData;
  const { currentState } = useSelector((state: IApplicationState) => state.ussdFlow);
  const [msisdnValue, setMsisdnValue] = useState<string>(props.nextData.msisdn || props.msisdn);
  const isHeaderEnrichmentSuccess = !!props.nextData.isHeaderEnrichmentSuccess
    ? props.nextData.isHeaderEnrichmentSuccess
    : false;

  useEffect(() => {
    handleAutoSubmitMsisdn({
      msisdnValue,
      dispatch,
      submitMSISDNAction,
      isHeaderEnrichmentSuccess,
      setMsisdnValue
    });
  }, []);

  return (
    <TranslationProvider locale={props.locale}>
      <div>

        {/* top legals */}
        <div className="topLegalsWrap">
          <TopLegals className="topLegals" locale={props.locale} legals={legals} />
        </div>

        <div id="divFocus">
          {
            ussdFlowMatch({
              msisdnEntry: (rds) => {
                return (
                  <div>
                    {RDS.WhenSuccess<MSISDNEntrySuccess, JSX.Element>(
                      <PhoneNumberEntryStep
                        isHeaderEnrichmentSuccess={isHeaderEnrichmentSuccess}
                        msisdn={msisdnValue}
                        operator={props.operator}
                        rds={rds}
                        locale={props.locale}
                        onEnd={(_msisdn, bupperNumber, extraParams) => {
                          props.setMsisdn(_msisdn);
                          dispatch(UssdFlowActionMaps[nextAction](_msisdn));
                        }}
                      />,
                      (data) => <USSD {...data} />
                    )(rds)}
                  </div>
                );
              }
            })
            (currentState)
            // (mockedMSISDNEntrySuccess.currentState)
            // (mockedMSISDNEntryFailure.currentState)
          }
        </div>

        {/* price point */}
        <PricePoint
          className="price-point"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

        {/* disclaimers */}
        <Disclaimers
          className="disclaimer"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

      </div>
    </TranslationProvider>
  );
};
export default UssdFlow;
