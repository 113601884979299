// dependencies
import { defineMessages, Disclaimers, PricePoint, TopLegals } from 'ouisys-component-library';
import pinFlowMatch from 'ouisys-engine/dist/flows/pinFlow';
import { backToStart, submitMSISDNAction, submitPinAction } from 'ouisys-engine/dist/reducers/pinFlow';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import { handleAutoSubmitMsisdn } from 'ouisys-engine/dist/utilities/handleSubmitNumber';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local
import config from '../../../config.json';
import PhoneNumberEntryStep from '../../components/PhoneNumberEntryStep';
import PINEntryStep from '../../components/PINEntryStep';
import { legals, TranslationProvider } from '../../localization/index';

// error messages messages which might come from backend
const defaultMessages = defineMessages({
  InvalidMSISDN: {
    id: 'InvalidMSISDN',
    defaultMessage: 'This mobile number is incorrect, try again'
  },
  AlreadySubscribed: {
    id: 'AlreadySubscribed',
    defaultMessage: 'You are already a subscriber!'
  },
  UnknownError: {
    id: 'UnknownError',
    defaultMessage: 'An unknown error occurred.'
  },
  InvalidPIN: {
    id: 'InvalidPIN',
    defaultMessage: 'Please enter the correct PIN that you received on your phone'
  },
  TooEarly: {
    id: 'TooEarly',
    defaultMessage: 'You must first submit a mobile number'
  },
  didnt_receive_pin_yet: {
    id: 'didnt_receive_pin_yet',
    defaultMessage: "Didn't receive PIN yet?"
  }
});

const PinFlow = (props: {
  nextData: IdentifyFlowOptionsResult;
  locale: string;
  msisdn: string;
  operator: string;
  setMsisdn: (msisdn: string) => void;
}) => {
  const dispatch = useDispatch();
  const { nextAction } = props.nextData;
  const { currentState } = useSelector((state: IApplicationState) => state.pinFlow);
  const [msisdnValue, setMsisdnValue] = useState<string>(props.nextData.msisdn || props.msisdn);
  const isHeaderEnrichmentSuccess = props.nextData.isHeaderEnrichmentSuccess
    ? props.nextData.isHeaderEnrichmentSuccess
    : false;

  useEffect(() => {
    handleAutoSubmitMsisdn({
      msisdnValue,
      dispatch,
      submitMSISDNAction,
      isHeaderEnrichmentSuccess,
      setMsisdnValue
    });
  }, []);

  return (
    <TranslationProvider locale={props.locale}>
      <div>

        {/* top legals */}
        <div className="topLegalsWrap">
          <TopLegals className="topLegals" locale={props.locale} legals={legals} />
        </div>

        <div id="divFocus">
          {
            pinFlowMatch({
              msisdnEntry: (rds) => (
                <PhoneNumberEntryStep
                  isHeaderEnrichmentSuccess={isHeaderEnrichmentSuccess}
                  msisdn={msisdnValue}
                  operator={props.operator}
                  rds={rds}
                  locale={props.locale}
                  onEnd={(_msisdn, bupperNumber, extraParams) => {
                    props.setMsisdn(_msisdn);
                    dispatch(submitMSISDNAction(_msisdn));
                  }}
                />
              ),
              pinEntry: (rds, nextPinData) => (
                <PINEntryStep
                  onEnd={(pin) => dispatch(submitPinAction(props.msisdn, pin, { language_code: props.locale }))}
                  backToStart={() => dispatch(backToStart())}
                  msisdn={props.msisdn}
                  rds={rds}
                  locale={props.locale}
                  pinMaxLength={nextPinData?.pinMaxLength || ''}
                />
              )
            })(currentState)
            // (mockedPINState.currentState)
            // (mockedCompletedState.currentState)
            // (mockedMSISDNEntryFailure.currentState)
          }
        </div>

        {/* price point */}
        <PricePoint
          className="price-point"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

        {/* disclaimers */}
        <Disclaimers
          className="disclaimer"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

      </div>
    </TranslationProvider>
  );
};
export default PinFlow;
