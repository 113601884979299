// dependencies
import {Disclaimers, PricePoint, TopLegals, defineMessages, Link } from 'ouisys-component-library';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import oneClickFlowMatch from 'ouisys-engine/dist/flows/oneClickFlow';
import { getRedirectUrlAction, subscribeUrlAction } from 'ouisys-engine/dist/reducers/oneClickFlow';
import {
  IGETREDIRECTURLFailure,
  IGETREDIRECTURLSuccess,
  SubscribeFailure,
  SubscribeSuccess
} from 'ouisys-engine/dist/reducers/oneClickFlow/OneClickTypes';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { searchToObject } from 'ouisys-engine/dist/reducers/strategy/utils';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local
import Loader from '../../components/Loader';
import { legals, Translate, TranslationProvider } from '../../localization/index';
import config from '../../../config.json';

// error messages messages which might come from backend
const defaultMessages = defineMessages({
  SubscriptionError: {
    id: 'SubscriptionError',
    defaultMessage: 'Subscription Error!'
  }
});

const SubscribeConfirmButton = () => {
  const dispatch = useDispatch();
  return (
    <Link
      isDcbp
      className="subscriptionArea"
      onClick={(ev) => {
        ev.preventDefault();
        dispatch(subscribeUrlAction(true));
      }}
    >
      <div className="clickLabel">
        <Translate
          id="one-click-subscribe-confirm-label"
          defaultMessage="One Click Subscribe Confirm label"
        />
      </div>
      <div className="btn">
        <Translate
          id="one-click-subscribe-confirm-btn-cta"
          defaultMessage="One Click Subscribe Confirm btn CTA"
        />
      </div>
    </Link>
  );
};

const SubscribeThankYou = (productUrl) => (
  <Link
    className="subscriptionArea"
    onClick={() => {
      tracker.advancedInFlow(
        'tallyman.v1-one-click',
        'click-product-url',
        { product_url: productUrl }
      );
    }}
    href={productUrl}
  >
    <div className="clickLabel">
      <Translate
        id="one-click-subscribe-confirm-label"
        defaultMessage="One Click Subscribe Confirm label"
      />
    </div>
    <div className="btn">
      <Translate
        id="one-click-subscribe-confirm-btn-cta"
        defaultMessage="One Click Subscribe Confirm btn CTA"
      />
    </div>
  </Link>
);

const OneClickFlow = (props: {
  nextData: IdentifyFlowOptionsResult;
  locale: string;
  operator: string;
}) => {
  const dispatch = useDispatch();
  const { nextAction, autoRedirect } = props.nextData;
  const { currentState } = useSelector((state: IApplicationState) => state.oneClickFlow);
  const search = searchToObject();
  const isAgency = search['partnership_type'] && search['partnership_type'] === 'agency';
  const redirectUrl = useOneClickRedirectUrl(useSelector)

  useEffect(() => {
    dispatch(getRedirectUrlAction({ autoRedirect, isGetRedirectBupperWay: false }));
  }, []);

  return (
    <TranslationProvider locale={props.locale}>
      <div>

        {/* top legals */}
        <div className="topLegalsWrap">
          <TopLegals className="topLegals" locale={props.locale} legals={legals} />
        </div>

        {isAgency && (
          <Link id="evina-one-click" className="subscriptionArea" isDcbp isMcp>
            <Translate id="one-click-label" defaultMessage="One Click label" />
          </Link>
        )}

        <div id="divFocus">
          {(!autoRedirect &&
            oneClickFlowMatch({
              getRedirectUrl: (rds) => {
                return (
                  <div>
                    {RDS.match<IGETREDIRECTURLFailure, IGETREDIRECTURLSuccess, any>({
                      failure: (err: IGETREDIRECTURLFailure) => console.log(err),
                      nothingYet: () => {
                        //dispatch(getRedirectUrlAction())
                      },
                      loading: () => console.log('loading...'),
                      success: ({ redirectUrl }) => {
                        return (
                          <Link
                            isDcbp
                            isMcp
                            className="subscriptionArea"
                            onClick={() => {
                              tracker.advancedInFlow(
                                'tallyman.v1-one-click',
                                'click-redirect-url',
                                {
                                  redirect_url: redirectUrl
                                }
                              );
                            }}
                            href={redirectUrl}
                          >
                            <div className="clickLabel">
                              <Translate id="one-click-label" defaultMessage="One Click label" />
                            </div>
                            <div className="btn">
                              <Translate id="one-click-btn-cta" defaultMessage="One Click btn CTA" />
                            </div>
                          </Link>
                        );
                      }
                    })(rds)}
                  </div>
                );
              },
              subscribe: (rds) => {
                return (
                  <div>
                    {RDS.WhenSuccess<SubscribeSuccess, JSX.Element>(<SubscribeConfirmButton />, (data) => (
                      <SubscribeThankYou {...data} />
                    ))(rds)}
                    {RDS.WhenLoading(null, () => (
                      <div className="loading-wrapper">
                        <Loader />
                        <div>
                          <Translate id="loadingLabel" defaultMessage="Please wait..." />
                        </div>
                      </div>
                    ))(rds)}
                    {RDS.WhenFailure(null, (err: SubscribeFailure) => {
                      return (
                        <div className="errorMsg">
                          <Translate id={err.errorType} />
                          <br />
                          {err.errorType == 'AlreadySubscribed' && (
                            <Link target="_blank" href={err.productUrl}>
                              <Translate id="goToProductLabel" defaultMessage="Go to Product" />
                            </Link>
                          )}
                        </div>
                      );
                    })(rds)}
                  </div>
                );
              }
            })
            (currentState)) || (
            <div className="loading-wrapper">
              <Loader />
              <div>
                <Translate id="loadingLabel" defaultMessage="Please wait..." />
              </div>
            </div>
          )}
        </div>

        {/* price point */}
        <PricePoint
          className="price-point"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

        {/* disclaimers */}
        <Disclaimers
          className="disclaimer"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

      </div>
    </TranslationProvider>
  );
};
export default OneClickFlow;
