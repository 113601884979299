import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/stable';
import { RealTallyman } from 'ouisys-engine/dist/api';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import Root from './Root';

export default Root;
window.tallymanApi = RealTallyman;
const container = document.querySelector('#root');
const root = createRoot(container!);

if (!module.hot) root.render(<Root />);
