// dependencies
import { MOLink as Click2SmsMoLink, Disclaimers, PricePoint, TopLegals, Link } from 'ouisys-component-library';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import { loadOc2sms } from 'ouisys-engine/dist/reducers/click2smsFlow';
import { IKeywordShortcode } from 'ouisys-engine/dist/reducers/click2smsFlow/Click2smsTypes';
import { checkSubscriptionAction, tracker } from 'ouisys-engine/dist/reducers/strategy';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// local
import Loader from '../../components/Loader';
import { legals, Translate, TranslationProvider } from '../../localization/index';
import config from '../../../config.json';

const Click2smsFlow = (props: { nextData: IdentifyFlowOptionsResult; locale: string; operator: string }) => {
  const dispatch = useDispatch();
  const { nextAction, operator } = props.nextData;
  const currentState = useSelector((state: IApplicationState) => state.click2smsFlow.currentState.result);

  useEffect(() => {
    dispatch(loadOc2sms());
    // uncomment this for auto-click
    /* setTimeout(() => {
      if(document.getElementById('click2smslink') !== null){
        document.getElementById('click2smslink').click()
      }
    }, 0); */
  }, []);

  const { keyword, shortcode } = RDS.WhenSuccess<IKeywordShortcode, IKeywordShortcode>(
    { keyword: '', shortcode: '' },
    (kw) => kw
  )(currentState);

  const MOLink = RDS.match<string, IWithMultiKeywordShortcode, ACompType>({
    nothingYet:
      () =>
      ({ children, ...props }) =>
        (
          <Link data-state="nothingYet" onClick={() => console.info('nothingYet')} href="javascript:void(0);" {...props}>
            {children}
          </Link>
        ),
    loading:
      () =>
      ({ children, ...props }) =>
        (
          <Link data-state="loading" onClick={() => console.info('loading')} href="javascript:void(0);" {...props}>
            {children}
          </Link>
        ),
    failure:
      (error) =>
      ({ children, ...props }) =>
        (
          <Link data-state="failure" onClick={() => console.error(error)} href="javascript:void(0);" {...props}>
            {children}
          </Link>
        ),
    success:
      (result) =>
      ({ children, keyword, shortcode, operators, ...props }) => {
        if (result.type == 'SingleSubmissionResult') {
          const keywordAndShortcode1 = {
            keyword: keyword || result.keyword,
            shortcode: shortcode || result.shortcode
          };
          return (
            <Click2SmsMoLink
              onClick={() => {
                tracker.advancedInFlow(
                  'click2sms',
                  'click',
                  keywordAndShortcode1
                );
                dispatch(
                  checkSubscriptionAction({
                    flow: 'click2sms',
                    action: 'click',
                    args: keywordAndShortcode1
                  })
                );
              }}
              keywordAndShortcode={keywordAndShortcode1}
              {...props}
            >
              {children}
            </Click2SmsMoLink>
          );
        }
        return (
          <>
            {result.data &&
              result.data.map((obj, index) => {
                const keywordAndShortcode1 = {
                  keyword: keyword || obj.keyword,
                  shortcode: shortcode || obj.shortcode
                };
                if (!!operators && operators.length > 0 && operators.includes(obj.operator)) {
                  return (
                    <Click2SmsMoLink
                      key={index}
                      className="dcbp"
                      onClick={() => {
                        tracker.advancedInFlow(
                          'click2sms',
                          'click',
                          keywordAndShortcode1
                        );
                        dispatch(
                          checkSubscriptionAction({
                            flow: 'click2sms',
                            action: 'click',
                            args: keywordAndShortcode1
                          })
                        );
                      }}
                      keywordAndShortcode={keywordAndShortcode1}
                      {...props}
                    >
                      {children}
                    </Click2SmsMoLink>
                  );
                }
                if (!operators) {
                  return (
                    <Click2SmsMoLink
                      key={index}
                      onClick={() => {
                        tracker.advancedInFlow(
                          'click2sms',
                          'click',
                          keywordAndShortcode1
                        );
                        dispatch(
                          checkSubscriptionAction({
                            flow: 'click2sms',
                            action: 'click',
                            args: keywordAndShortcode1
                          })
                        );
                      }}
                      keywordAndShortcode={keywordAndShortcode1}
                      {...props}
                    >
                      {children}
                    </Click2SmsMoLink>
                  );
                }
              })}
          </>
        );
      }
  })(currentState);

  return (
    <TranslationProvider locale={props.locale}>
      <div>
        
        {/* top legals */}
        <div className="topLegalsWrap">
          <TopLegals className="topLegals" locale={props.locale} legals={legals} />
        </div>

        <div id="divFocus">
          <div>

            <div className="operator-text">
              {
                (operator === 'ID_XL' && <span>Eksklusif untuk pelanggan XL</span>) ||
                (operator === 'ID_TELKOMSEL' && <span>Eksklusif untuk pelanggan TELKOMSEL</span>) ||
                (operator === 'ID_HUTCH' && <span>Eksklusif untuk pelanggan HUTCH</span>) ||
                (operator === 'ID_ISAT' && <span>Eksklusif untuk pelanggan ISAT</span>)
              }
            </div>

            {/*
              This example shows how you can override the default keyword by
              specifying another keyword. Use any className that you want of course.
            */}

            {/* comment out to mock multi-operator */}
            {
              (currentState.data && currentState.data.type) === 'MultiOperatorSubmissionResult' && (
                <ul className="btn-list">
                  <li>
                    <MOLink operators={['ID_TELKOMSEL']} className="btn oc2sms-button">
                      <Translate id="id_telkmomsel" defaultMessage="TELKOMSEL" />
                    </MOLink>
                  </li>
                  <li>
                    <MOLink operators={['ID_HUTCH']} className="btn oc2sms-button">
                      <Translate id="id_hutch" defaultMessage="HUTCH" />
                    </MOLink>
                  </li>
                  <li>
                    <MOLink operators={['ID_XL']} className="btn oc2sms-button">
                      <Translate id="id_xl" defaultMessage="XL" />
                    </MOLink>
                  </li>
                  <li>
                    <MOLink operators={['ID_ISAT']} className="btn oc2sms-button">
                      <Translate id="id_isat" defaultMessage="ISAT" />
                    </MOLink>
                  </li>
                </ul>
              )
            }

            {/* comment out to mock single-operator */}
            {
              (currentState.data && currentState.data.type) === 'SingleSubmissionResult' && (
                <MOLink id="click2smslink" className="subscriptionArea oc2sms-button">
                  <div className="oc2smsLabel">
                    <Translate
                      id="oc2smsLabel"
                      defaultMessage="Click on your mobile operator to download your content now"
                    />
                  </div>
                  <div className="btn">
                    <Translate id="sms_now" defaultMessage="SMS NOW" />
                  </div>
                </MOLink>
              )
            }

            {RDS.WhenLoading(null, () => (
              <div className="loading-wrapper">
                <Loader />
                <div>
                  <Translate id="loadingLabel" defaultMessage="Please wait..." />
                </div>
              </div>
            ))(currentState)}

          </div>
        </div>

        {/* price point */}
        <PricePoint
          className="price-point"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

        {/* disclaimers */}
        <Disclaimers
          className="disclaimer"
          locale={props.locale}
          legals={legals}
          flow={(config as any).strategyConfigs.default.flow}
        />

      </div>
    </TranslationProvider>
  );
};

export default Click2smsFlow;
// In the Netherlands use this instead of the above line:
// export default HOC(tracker, Root, {tag: "keywordAndShortCode", shortcode: "8010", keyword: "Geld"})(initialState);
