import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import creditCardFlowMatch from 'ouisys-engine/dist/flows/creditCardFlow';
import { submitUserDetailsAction } from 'ouisys-engine/dist/reducers/creditCardFlow';
import { IUserDetails, SubmitUserDetailsSuccess, IUserCreditCardDetails } from 'ouisys-engine/dist/reducers/creditCardFlow/CreditCardTypes';
import { IdentifyFlowOptionsResult } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { IApplicationState } from 'ouisys-engine/dist/store/reducers';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreditCardStep from '../../components/CreditCardStep';
import UserDetailsEntryStep from '../../components/UserDetailsEntryStep';
import { TranslationProvider } from '../../localization/index';
import { submitMaxPayInfo } from 'ouisys-engine/src/reducers/creditCardFlow';

const CreditCardFlow = (props: {
  nextData: IdentifyFlowOptionsResult;
  locale: string;
}) => {
  const { nextAction } = props.nextData;
  const { currentState } = useSelector((state: IApplicationState) => state.creditCardFlow);
 
  const dispatch = useDispatch();

  return (
    <TranslationProvider locale={props.locale}>
      <div>
        {
          creditCardFlowMatch({
            userDetailsEntry: (rds) => (
              <div>
                {RDS.WhenSuccess<SubmitUserDetailsSuccess, JSX.Element>(
                  <UserDetailsEntryStep
                    rds={rds}
                    locale={props.locale}
                    onEnd={(userDetails: IUserCreditCardDetails) => {
                      dispatch(submitUserDetailsAction(userDetails));
                    }}
                  />,
                  (data) => <CreditCardStep {...data} />
                )(rds)}
              </div>
              
            )
          })(currentState)
        }

      </div>
    </TranslationProvider>
  );
};

export default CreditCardFlow;
