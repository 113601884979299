// dependencies
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import React, { useEffect } from 'react';

// local
import { injectIntl } from '../../localization/index';

interface IChainRedirectProps {
  chainRedirectUrl: string;
}

const ChainRedirect = ({ chainRedirectUrl }: IChainRedirectProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.pac_analytics && !!window.pac_analytics.visitor) {
      const { visitor } = window.pac_analytics;
      const { rockmanId, handle_name, offer } = visitor;
      // example of chainRedirectUrl http://w1.buz2mobile.com/bh/chaintoact-bh?device=smart&offer=821&legalCheckbox=Y
      const redirectUrl = `${chainRedirectUrl}&pubid=${handle_name}&clickid=${rockmanId}&sub_id=${offer}&operator=${window.operator}`;
      setTimeout(() => {
        tracker.customEvent('Chain-Redirect', 'redirect', 'ChainFlow', { redirectUrl });
        window.location.href = redirectUrl;
      }, 2000);
    }
  }, []);

  return (
    <div>
      <h3>Redirecting...</h3>
    </div>
  );
};

export default injectIntl(ChainRedirect);
