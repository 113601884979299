// dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import { StrategyFlowActionMaps } from 'ouisys-engine/dist/reducers/strategy';
import { OPERATORSelectFlowIdentifySuccess } from 'ouisys-engine/dist/reducers/strategy/StrategyTypes';
import { Button } from 'ouisys-component-library';

// local
import { injectIntl, Translate } from '../../localization/index';

const OperatorSelection = (props: { setOperator(operator: string): void } & OPERATORSelectFlowIdentifySuccess) => {
  const dispatch = useDispatch();
  const { operators, nextAction } = props;
  const handleOperatorSelect = (operator: string) => {
    props.setOperator(operator);
    dispatch(StrategyFlowActionMaps[nextAction](operator));
  };

  return (
    <div className="subscriptionArea">
      <div className="operatorSelectionLabel">
        <Translate
          id="operatorSelectionLabel"
          defaultMessage="Select your operator to receive exclusive offer now!"
        />
      </div>
      {operators.map((operator, index) => (
        <Button key={operator+'-'+index} onClick={() => handleOperatorSelect(operator)}>
          {operator.toUpperCase()}
        </Button>
      ))}
    </div>
  );
};

export default injectIntl(OperatorSelection);
