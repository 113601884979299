// dependencies
import { IKeywordShortcode, Link } from 'ouisys-component-library';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { backToStart } from 'ouisys-engine/dist/reducers/ussdFlow';
import React from 'react';
import { useDispatch } from 'react-redux';

// local
import { injectIntl, Translate } from '../../localization/index';
import Loader from '../Loader';

const USSD = ({ keyword, shortcode }: IKeywordShortcode) => {
  const dispatch = useDispatch();

  return (
    <div className="USSD">
      <Loader />
      <Link
        className="tryAgainLink"
        onClick={() => {
          dispatch(backToStart());
          tracker.recedeInFlow(
            'tallyman.v1-pin',
            'MSISDN_ENTRY',
            'requested to change phone number'
          );
        }}
      >
        <Translate id="tryAgainLabel" defaultMessage="Try again" />
      </Link>
    </div>
  );
};

export default injectIntl(USSD);
