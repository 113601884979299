// dependencies
import React, { useRef, useState } from 'react';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import PhoneInput from 'ouisys-phone-input/dist/common/PhoneInput';
import { MSISDNEntryFailure, MSISDNEntrySuccess } from 'ouisys-engine/dist/reducers/pinFlow/PinTypes';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { handleFormSubmit } from 'ouisys-engine/dist/utilities/handleSubmitNumber';
import { defineMessages, Button, Link } from 'ouisys-component-library';

// local
import { injectIntl, Translate } from '../../localization/index';
import Loader from '../Loader/index';

const defaultMessages = defineMessages({
  phoneInput: {
    id: "phoneInput",
    defaultMessage: "Mobile Number",
  },
});

interface PhoneNumberEntryStepProps {
  msisdn: string;
  rds: RDS.RemoteDataState<MSISDNEntryFailure, MSISDNEntrySuccess>;
  onEnd: (msisdn: string, extraParams?: { [key: string]: string }) => void;
  isHeaderEnrichmentSuccess: boolean;
  locale: string;
  intl: any;
}

const PhoneNumberEntryStep = (props: PhoneNumberEntryStepProps) => {
  const { intl } = props;
  const [msisdn, setMsisdn] = useState<string>(props.msisdn);
  const [internationalNumber, setInternationalNumber] = useState<string>(props.msisdn);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isMsisdnFilled, setIsMsisdnFilled] = useState<boolean>(false);
  const phoneInputRef = useRef<HTMLInputElement>();

  console.log("process.env.country", process.env.country);

  return (
    <div className="subscriptionArea">
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          handleFormSubmit({
            isHeaderEnrichmentSuccess: props.isHeaderEnrichmentSuccess,
            country: process.env.country,
            setInternationalNumber,
            onEnd: props.onEnd,
            internationalNumber
          });
        }}
      >

        <div className="number-entry">
          {
            (!props.isHeaderEnrichmentSuccess && (
              // normal msisdn entry
              <div>
                <h1 className="msisdnLabel">
                  <Translate id="msisdnLabel" defaultMessage="Put your mobile number below" />
                </h1>
                <div className="phoneInputWrap">
                  <PhoneInput
                    inputElementRef={phoneInputRef}
                    placeholder={intl.formatMessage(defaultMessages.phoneInput)}
                    msisdn={msisdn}
                    countryCode={process.env.country}
                    showFlag={false}
                    showMobileIcon={true}
                    showError={true}
                    onChange={({ msisdn, isValid, internationalNumber }) => {
                      setMsisdn(msisdn);
                      setIsValid(isValid);
                      setInternationalNumber(internationalNumber);
                    }}
                    onKeyPress={(ev) => {
                      if (!isMsisdnFilled && ev.target.value.length >= 5) {
                        setIsMsisdnFilled(true);
                      }
                    }}
                    onPaste={(ev) => {
                      if (!isMsisdnFilled) {
                        setIsMsisdnFilled(true);
                      }
                      setTimeout(() => {
                        tracker.customEvent(
                          'MSISDN-State',
                          'copypaste',
                          'MsisdnPasted',
                            {
                              msisdn: ev.target.value
                            }
                        );
                      }, 0);
                      
                    }}
                    onBlur={(ev) => {
                      if (!isMsisdnFilled && ev.target.value.length >= 5) {
                        setIsMsisdnFilled(true);
                        tracker.customEvent(
                          'MSISDN-State',
                          'autofill',
                          'AutoFill',
                          {
                            msisdn: ev.target.value
                          }
                        );
                      }
                    }}
                  />
                </div>
                <Button
                  id="msisdn-submit-button"
                  type="submit"
                  className="btn"
                  disabled={RDS.IsLoading(props.rds) || !isValid}
                >
                  <Translate id="msisdnButton" defaultMessage="Send SMS Now" />
                </Button>
              </div>
            )) || (
              // header enrichment
              <div>
                <h1 className="msisdnLabel">
                  <Translate id="msisdnLabelForHe" defaultMessage="Click To Send Pin" />
                </h1>
                <Button id="msisdn-submit-button" type="submit" className="btn" isDcbp>
                  <Translate id="subscribe" defaultMessage="Subscribe Now" />
                </Button>
              </div>
            )
          }
          {RDS.WhenLoading(null, () => (
            <div className="loading-wrapper">
              <Loader />
              <div>
                <Translate id="loadingLabel" defaultMessage="Please wait..." />
              </div>
            </div>
          ))(props.rds)}
        </div>

        <div>
          {RDS.WhenFailure(null, (err: MSISDNEntryFailure) => {
            return (
              <div className="errorMsg">
                <Translate id={err.errorType} />
                <br />
                {err.errorType == 'AlreadySubscribed' && (
                  <Link target="_blank" href={err.productUrl}>
                    <Translate id="goToProductLabel" defaultMessage="Go to Product" />
                  </Link>
                )}
              </div>
            );
          })(props.rds)}
        </div>

      </form>
    </div>
  );
};

export default injectIntl(PhoneNumberEntryStep);
