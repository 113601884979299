// dependencies
import React, { useState } from 'react';
import * as RDS from 'ouisys-engine/dist/common-types/RemoteDataState';
import { PINEntryFailure, PINEntrySuccess } from 'ouisys-engine/dist/reducers/pinFlow/PinTypes';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { defineMessages, Button, Link } from 'ouisys-component-library';

// local
import { injectIntl, Translate } from '../../localization/index';
import Loader from '../Loader/index';
import ThankYouStep from '../ThankYouStep';

const defaultMessages = defineMessages({
  pinInput: {
    id: "pinInput",
    defaultMessage: "PIN",
  },
});

interface PINEntryStepProps {
  msisdn: string;
  rds: RDS.RemoteDataState<PINEntryFailure, PINEntrySuccess>;
  backToStart: () => void;
  onEnd: (pin: string) => void;
  locale: string;
  pinMaxLength: number;
  intl: any;
}

const PINEntryStep = (props: PINEntryStepProps) => {
  const { intl } = props;
  const [pin, setPin] = useState<string>('');

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        props.onEnd(pin);
      }}
    >

      <div className="subscriptionArea">
        {RDS.WhenSuccess<PINEntrySuccess, JSX.Element>(
          <div>
            <div className="pinEntryLabel">
              <Translate
                id="pinEntryLabel"
                defaultMessage="Enter pin number sent to you via SMS to complete verification:"
              />
            </div>
            <div className="pinInputWrap">
              <input
                className="pinInput"
                type="text"
                placeholder={intl.formatMessage(defaultMessages.pinInput)}
                value={pin}
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={props.pinMaxLength ? props.pinMaxLength : 4}
                minLength={4}
                autoComplete="one-time-code"
                onChange={(ev) => setPin(ev.target.value)}
              />
            </div>
            <Button
              type="submit"
              className="btn"
              disabled={RDS.IsLoading(props.rds) || (pin.length < 4)}
            >
              <Translate id="pinButton" defaultMessage="Confirm Pin Entry" />
            </Button>
          </div>,
          ({ finalUrl, isAlreadySubscribed }) => (
            <ThankYouStep finalUrl={finalUrl} isAlreadySubscribed={isAlreadySubscribed} />
          )
        )(props.rds)}
      </div>

      {RDS.match<PINEntryFailure, PINEntrySuccess, any>({
        failure: (err: PINEntryFailure) => (
          <div>
            <div className="errorMsg">
              <Translate id={err.errorType} />
            </div>
          </div>
        ),
        nothingYet: () => (
          <Link
            className="tryAgainLink"
            id="backToStart"
            onClick={() => {
              props.backToStart();
              tracker.recedeInFlow('tallyman.v1-pin', 'MSISDN_ENTRY', 'requested to change phone number');
            }}
          >
            <Translate
              id="tryAgainLabel"
              defaultMessage="If {phone} is not your mobile number, Click here to try again"
              values={{
                phone: props.msisdn
              }}
            />
          </Link>
        ),
        loading: () => (
          <div className="loading-wrapper">
            <Loader />
            <div>
              <Translate id="loadingLabel" defaultMessage="Please wait..." />
            </div>
          </div>
        ),
        success: ({ finalUrl }) => null
      })(props.rds)}

    </form>
  );
};

export default injectIntl(PINEntryStep);
