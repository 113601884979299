// dependencies
import React, { useEffect } from 'react';
import { tracker } from 'ouisys-engine/dist/reducers/strategy';
import { MSISDNEntrySuccess } from 'ouisys-engine/dist/reducers/moRedirFlow/MoRedirTypes';
import { Link } from 'ouisys-component-library';

// local
import { injectIntl, Translate } from '../../localization/index';

const MORedir = ({ href, backToStart }: MSISDNEntrySuccess & { backToStart: () => void }) => {
  // uncomment this if you need auto redirection
  useEffect(() => {
    if (typeof window !== 'undefined') {
      tracker.advancedInFlow(
        'tallyman.v1-click-redir',
        'click',
        { url: href }
      );
      window.location.href = href;
    }
  },[]);

  return (
    <div className="instructions">
      <Translate id="goToProductLabel" defaultMessage="Go to Product" />
      <div className="try-again">
        <Link onClick={() => backToStart()}>
          <Translate id="tryAgainLabel" defaultMessage="Try again" />
        </Link>
      </div>
    </div>
  );
};

export default injectIntl(MORedir);
